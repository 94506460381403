<template>
    <label
        v-b-tooltip.hover.top
        :title="titleString"
        :style="styleString"
        :class="classString"
        >{{ nameString }}</label
    >
</template>

<script>
export default {
    name: "Pill",
    props: {
        styleString: {
            type: String
        },
        classString: {
            type: String,
            default:
                "btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active pill-test"
        },
        nameString: {
            type: String,
            default: "Name String"
        },
        titleString: {
            type: String
        }
    }
};
</script>

<style></style>
