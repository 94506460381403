<template>
    <div>
        <base-header v-if="!isBooking" base-title="Booking"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh
                                :table-name="tableName"
                                :on-refresh="fetch"
                            ></auto-refresh
                            >&nbsp;&nbsp;
                            <button
                                class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                                @click.prevent="setIsBooking(false)"
                                v-if="isFo"
                            >
                                back
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mr-3 d-flex justify-content-end">
                    <quick-filter
                        :changedValueStart.sync="qckTglKunjunganPickedStart"
                        :changedValueEnd.sync="qckTglKunjunganPickedEnd"
                    ></quick-filter>
                </div>
                <div class="kt-portlet__body">
                    <v-server-table
                        id="table_kasir"
                        :columns="columns"
                        :options="options"
                        name="kasir_table"
                        ref="table"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <btn-action
                                :rowData="row"
                                :kasirViewVue="kasirViewVue"
                            ></btn-action>
                        </div>
                        <div slot="status" slot-scope="{ row }">
                            <pill
                                :nameString="status(row)"
                                :classString="
                                    'btn btn-elevate btn-pill btn-sm mr-2'
                                "
                                :styleString="
                                    status(row) == 'UNPAID'
                                        ? 'background-color: #ff0000a1;color: white;min-width: 95px;'
                                        : 'background-color: #1dc9b7;color: white;min-width: 95px;'
                                "
                            >
                            </pill>
                        </div>
                        <div slot="paymentType" slot-scope="{ row }">
                            <pill
                                :nameString="row.paymentType"
                                :classString="
                                    'btn btn-elevate btn-pill btn-sm mr-2'
                                "
                                :styleString="
                                    row.paymentType == 'ONLINE'
                                        ? 'background-color: #edeeff;color: black;min-width: 70px;'
                                        : 'background-color: #01046c;color: white;min-width: 70px;'
                                "
                            >
                            </pill>
                        </div>
                        <div slot="status" slot-scope="{ row }">
                            <label
                                :class="'btn btn-elevate btn-pill btn-sm mr-2'"
                                :style="
                                    status(row) == 'UNPAID'
                                        ? 'background-color: #ff0000a1;color: white;min-width: 95px;'
                                        : 'background-color: #1dc9b7;color: white;min-width: 95px;'
                                "
                            >
                                {{ status(row) }}
                            </label>
                        </div>
                        <div slot="paymentType" slot-scope="{ row }">
                            <label
                                :class="'btn btn-elevate btn-pill btn-sm mr-2'"
                                :style="
                                    row.paymentType == 'ONLINE'
                                        ? 'background-color: #edeeff;color: black;min-width: 70px;'
                                        : 'background-color: #01046c;color: white;min-width: 70px;'
                                "
                            >
                                {{ row.paymentType }}
                            </label>
                        </div>
                        <div slot="filter__lastStatus">
                            <check-drop-down
                                :data="statusPicked"
                                :changedValue.sync="statusPicked"
                            ></check-drop-down>
                        </div>
                        <div slot="filter__tanggalKunjungan">
                            <!-- <date-range-picker
                                :changedValueStart.sync="
                                    tglKunjunganPickedStart
                                "
                                :changedValueEnd.sync="tglKunjunganPickedEnd"
                                id-date-picker="filter__tanggalKunjungan"
                                picker-value
                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                            ></date-range-picker> -->
                            <div style="position: relative;">
                                <date-range-picker
                                    :changedValueStart.sync="
                                        tglKunjunganPickedStart
                                    "
                                    :changedValueEnd.sync="
                                        tglKunjunganPickedEnd
                                    "
                                    id-date-picker="filter__tanggalKunjungan"
                                    picker-value
                                    place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                    :reset.sync="reset"
                                    :newDate="newDateString"
                                >
                                </date-range-picker>
                                <a
                                    class="clear-date-picker"
                                    v-show="tglKunjunganPickedStart"
                                    @click.prevent="clearDates"
                                    ><i class="fas fa-times"></i
                                ></a>
                            </div>
                        </div>
                        <div slot="filter__tglLahir">
                            <vue-date-picker
                                v-model="tglLahirPicked"
                                @change="filterByDob"
                                :name="'tanggalLahirFilter'"
                                :clearable="true"
                                :editable="false"
                                :disabled-date="'notAfterToday'"
                                :format="dateFormat"
                            >
                            </vue-date-picker>
                        </div>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../_base/BaseTitle.vue";
import BaseHeader from "../_base/BaseHeader.vue";
import BtnAction from "./components/BtnAction.vue";
import { Event } from "vue-tables-2";
import VueDatePicker from "../_general/VueDatePicker.vue";
import AutoRefresh from "../_general/AutoRefresh.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import CheckDropDown from "./components/CheckDropdown.vue";
import CheckPermission from "../../services/checkPermission.service";
const checkPermission = new CheckPermission();
const DriveThruRepository = RepositoryFactory.get("driveThru");
import DateRangePicker from "../_general/CustomDateRangePicker.vue";
import QuickFilter from "./components/QuickFilter.vue";
import Pill from "./components/Pill.vue";

export default {
    name: "Kasir-View",
    components: {
        BaseTitle,
        BaseHeader,
        BtnAction,
        VueDatePicker,
        AutoRefresh,
        CheckDropDown,
        QuickFilter,
        DateRangePicker,
        Pill
    },
    props: {
        isFo: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if (this.isBooking) {
            this.statusPicked = ["SET_PLAT_NUMBER"];
        } else {
            this.statusPicked = ["INPUT_NO_REGISTRASI_HIS"];
        }
    },
    mounted() {
        setTimeout(() => {
            this.first = true;
        }, 200);
        this.qckTglKunjunganPicked = `${moment()
            .subtract(2, "days")
            .format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`;
    },
    data() {
        var vx = this;
        return {
            isFirstLoad: false,
            headTitle: "Booking Table",
            tableName: "booking_table_kasir",
            kasirViewVue: vx,
            first: false,
            filterOptions: [],
            dateFormat: "DD-MMM-YYYY",
            tglLahirPicked: null,
            statusPicked: [],
            showAutoRefresh: true,
            tglKunjunganPickedStart: null,
            tglKunjunganPickedEnd: null,
            qckTglKunjunganPickedStart: null,
            qckTglKunjunganPickedEnd: null,
            newDateString: "",
            reset: false,
            columns: [
                "nama",
                "id",
                "tanggalKunjungan",
                "tglLahir",
                "paymentType",
                "totalPayment",
                "statusPasien",
                "lastStatus",
                "status",
                "action"
            ],
            options: {
                texts: {
                    filterBy: "Cari"
                },
                filterable: ["id", "nama"],
                headings: {
                    id: "ID",
                    statusPasien: "Status Pasien",
                    lastStatus: "Status Terakhir",
                    orderId: "Order ID",
                    nama: "Nama Pasien",
                    tglLahir: "Tanggal Lahir Pasien",
                    paymentType: "Metode Pembayaran",
                    totalPayment: "Total Payment",
                    status: "Status Pembayaran",
                    action: "Actions",
                    tanggalKunjungan: "Tanggal Kunjungan"
                },
                customFilters: ["lastStatus", "tglLahir", "tanggalKunjungan"],
                dateColumns: ["tglLahir", "tanggalKunjungan"],
                toMomentFormat: "YYYY-MM-DD",
                rowClassCallback(row) {
                    return row.isNewData ? "highlight" : "";
                },
                filterByColumn: true,
                requestFunction: function(data) {
                    let params = {};
                    params.page = data.page - 1;
                    params.size = data.limit;
                    params.requestFrom = "FO_KASIR";
                    const statusFlowTypes = vx.statusPicked;

                    if (
                        _.isEmpty(data.query) &&
                        !data.lastStatus &&
                        !data.tglLahir &&
                        _.isEmpty(data.tanggalKunjungan) &&
                        !vx.isFirstLoad
                    ) {
                        params.tglKunjunganStart = moment()
                            .subtract(2, "days")
                            .format("YYYY-MM-DD");
                        params.tglKunjunganEnd = moment().format("YYYY-MM-DD");
                        return DriveThruRepository.pcrBookingSearch(
                            params,
                            statusFlowTypes
                        ).catch(error => {
                            console.log(error);
                        });
                    } else if (vx.isFirstLoad) {
                        let tglKunjunganPicked;
                        if (vx.tglKunjunganPicked) {
                            let dates = vx.tglKunjunganPicked.split(" - ");
                            let start = dates[0];
                            let end = dates[1];
                            if (start !== "null" && end !== "null") {
                                tglKunjunganPicked = {
                                    start: moment(start).format("YYYY-MM-DD"),
                                    end: moment(end).format("YYYY-MM-DD")
                                };
                            }
                        }
                        if (data.tglLahir) {
                            params.tglLahir = data.tglLahir;
                        }
                        Object.keys(data.query).forEach(key => {
                            if (key == "bookingId") {
                                params.id = data.query[key];
                            } else if (key == "tanggalKunjungan") {
                                params.tglKunjunganStart =
                                    data.query.tanggalKunjungan.start ||
                                    moment()
                                        .subtract(2, "days")
                                        .format("YYYY-MM-DD");
                                params.tglKunjunganEnd =
                                    data.query.tanggalKunjungan.end ||
                                    moment().format("YYYY-MM-DD");
                            } else {
                                params[key] = data.query[key];
                            }
                        });
                        if (!_.isEmpty(data.tanggalKunjungan)) {
                            params.tglKunjunganStart =
                                data.tanggalKunjungan.start;
                            params.tglKunjunganEnd = data.tanggalKunjungan.end;
                        }
                        if (tglKunjunganPicked) {
                            params.tglKunjunganStart = tglKunjunganPicked.start;
                            params.tglKunjunganEnd = tglKunjunganPicked.end;
                        }

                        const statPasien = data.lastStatus
                            ? data.lastStatus
                            : statusFlowTypes;
                        return DriveThruRepository.pcrBookingSearch(
                            params,
                            statPasien
                        ).catch(error => {
                            console.log(error);
                        });
                    }
                },
                responseAdapter: resp => {
                    return {
                        data: resp.data,
                        count: resp.headers["x-total-count"]
                    };
                },
                templates: {
                    nama(h, row) {
                        let patientName = "";
                        row.testPemeriksaan.forEach(ele => {
                            if (ele.primaryPatient) {
                                patientName = `${ele.patientLab.namaDepan} ${
                                    ele.patientLab.namaBelakang
                                        ? ele.patientLab.namaBelakang
                                        : ""
                                }`;
                            }
                        });
                        patientName = patientName.replace(/\w\S*/g, function(
                            txt
                        ) {
                            return (
                                txt.charAt(0).toUpperCase() +
                                txt.substr(1).toLowerCase()
                            );
                        });
                        return patientName;
                    },

                    tglLahir(h, row) {
                        let patientTglLahir = null;
                        row.testPemeriksaan.forEach(ele => {
                            if (ele.primaryPatient) {
                                patientTglLahir = ele.patientLab.tglLahir;
                            }
                        });
                        patientTglLahir = moment(patientTglLahir).format(
                            "DD-MMM-YYYY"
                        );
                        return patientTglLahir;
                    },
                    tanggalKunjungan(h, row) {
                        const tgl = moment(row.tanggalKunjungan).format(
                            "DD-MMM-YYYY"
                        );
                        return tgl;
                    },
                    totalPayment(h, row) {
                        let totalPaid = 0;
                        row.testPemeriksaan.forEach(ele => {
                            ele.paketPemeriksaans.forEach(element => {
                                if (!element.cancel) {
                                    totalPaid += element.paketPemeriksaan.price;
                                }
                            });
                        });
                        totalPaid = totalPaid.toLocaleString("id", {
                            style: "currency",
                            currency: "IDR"
                        });
                        return totalPaid;
                    },
                    status(h, row) {
                        let status = "UNPAID";
                        row.statusFlow.forEach(ele => {
                            if (ele.status == "PAID") {
                                status = "PAID ONLINE";
                            }
                            if (ele.status == "PAID_MANUAL") {
                                status = "PAID OFFLINE";
                            }
                        });
                        return status;
                    },
                    statusPasien(h, row) {
                        let status = "BELUM DATANG";
                        row.statusFlow.forEach(el => {
                            if (
                                el.status == "PASIEN_DATANG" ||
                                el.status == "SET_PLAT_NUMBER" ||
                                el.status == "INPUT_NO_REGISTRASI_HIS"
                            ) {
                                status = "PASIEN DATANG";
                            }
                        });
                        return status;
                    },
                    lastStatus(h, row) {
                        return row.lastStatus.replaceAll("_", " ");
                    },
                    id(h, row) {
                        return row.orderId;
                    }
                }
            }
        };
    },
    destroyed() {
        this.setIsBooking(false);
        this.first = false;
    },
    methods: {
        ...mapActions("DriveThruStore", {
            postBookingCancel: "postBookingCancel"
        }),
        ...mapMutations("DriveThruStore", {
            setIsBooking: "SET_IS_BOOKING"
        }),
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        clearDates() {
            this.tglKunjunganPickedStart = null;
            this.tglKunjunganPickedEnd = null;
            this.qckTglKunjunganPickedStart = null;
            this.qckTglKunjunganPickedEnd = null;
            this.reset = true;
        },
        openDetail: function(id) {
            this.$router.push({
                name: "drive-thru-view",
                params: { id: id }
            });
        },
        PrimaryName(value) {
            let name = "";
            value.forEach(ele => {
                if (ele.primaryPatient) {
                    name = `${ele.patientLab.namaDepan} ${
                        ele.patientLab.namaBelakang
                            ? ele.patientLab.namaBelakang
                            : ""
                    }`;
                }
            });
            return name;
        },
        status(row) {
            let status = "UNPAID";
            row.statusFlow.forEach(ele => {
                if (ele.status == "PAID") {
                    status = "PAID ONLINE";
                }
                if (ele.status == "PAID_MANUAL") {
                    status = "PAID OFFLINE";
                }
            });
            return status;
        },
        cancelBookingan: function(orderId, encryptedId, test) {
            Swal.fire({
                title: `Batalkan Booking Milik ${this.PrimaryName(test)}`,
                confirmButtonText: "Batalkan Booking",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.postBookingCancel({
                        data: encryptedId,
                        orderId: orderId
                    });
                }
            });
        },
        fetch: function(resolve) {
            this.$refs.table.getData();
            if (resolve != undefined) {
                resolve();
            }
        },
        filterByDob() {
            Event.$emit(
                "vue-tables.kasir_table.filter::tglLahir",
                this.tglLahirPicked
            );
        }
    },
    watch: {
        statusPicked: function(val) {
            if (this.first) {
                Event.$emit("vue-tables.kasir_table.filter::lastStatus", val);
            }
        },
        isBooking: function(val) {
            if (val) {
                this.statusPicked = ["SET_PLAT_NUMBER"];
            } else {
                this.statusPicked = ["INPUT_NO_REGISTRASI_HIS"];
                this.$refs.table.getData();
            }
        },
        tglKunjunganPicked: function(val) {
            if (this.isFirstLoad) {
                let dates = val.split(" - ");
                let start = dates[0];
                let end = dates[1];
                if (start == "null" && end == "null") {
                    // start = moment().format("YYYY-MM-DD 00:00:00");
                    // end = moment().format("YYYY-MM-DD 23:59:59");
                    Event.$emit(
                        "vue-tables.kasir_table.filter::tanggalKunjungan",
                        ""
                    );
                } else {
                    Event.$emit(
                        "vue-tables.kasir_table.filter::tanggalKunjungan",
                        {
                            start: moment(start).format("YYYY-MM-DD"),
                            end: moment(end).format("YYYY-MM-DD")
                        }
                    );
                }
            } else {
                this.isFirstLoad = true;
            }
        },
        qckTglKunjunganPicked: function(val) {
            this.newDateString = val;
            let dates = val.split(" - ");
            let start = dates[0];
            let end = dates[1];
            this.tglKunjunganPickedStart = start;
            this.tglKunjunganPickedEnd = end;
        }
        // tglLahirPicked: function(val) {
        //     Event.$emit(
        //         "vue-tables.kasir_table.filter::tglLahir",
        //         this.tglLahirPicked
        //     );
        // }
    },
    computed: {
        ...mapState("DriveThruStore", {
            pcrOrder: state => state.pcrOrder,
            isLoading: state => state.isLoading,
            isBooking: state => state.isBooking
        }),
        tglKunjunganPicked: {
            get() {
                return `${this.tglKunjunganPickedStart} - ${
                    this.tglKunjunganPickedEnd
                }`;
            },
            set(val) {
                const date = val.split(" - ");
                this.tglKunjunganPickedStart = date[0];
                this.tglKunjunganPickedEnd = date[1];
            }
        },
        qckTglKunjunganPicked: {
            get() {
                return `${this.qckTglKunjunganPickedStart} - ${
                    this.qckTglKunjunganPickedEnd
                }`;
            },
            set(val) {
                const date = val.split(" - ");
                this.qckTglKunjunganPickedStart = date[0];
                this.qckTglKunjunganPickedEnd = date[1];
            }
        }
    }
};
</script>

<style></style>
